import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SitesSelect from '../../common/SitesSelect'
import AccountTypesSelect from '../../common/AccountTypesSelect'
import { HttpRequestService } from '../../services/HttpRequestService';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import CompanySelect from '../../common/CompanySelect';
import StateSelect from '../../common/StateSelect';
import Report from '../../common/Report';
import UserDefinedFieldEditor from '../../common/UserDefinedFieldEditor';

class CustomerCategoryReport extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var initialState = {
            beginDate: moment(new Date()).toDate(),
            endDate: moment(new Date()).toDate(),
            siteId: -1,
            accountTypeId: -1,
            groupBy: 7,
            companyId: -1,
            stateId: -1,
            report: null,
            udfValues: [],
            userDefinedFields: [],
        }
        this.sortBy = "";
        this.sortDirection = "";
        this.paramsKey = "params.customer-category-report";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);

        this.http = new HttpRequestService();
        this.handleBeginDateChange = this.handleBeginDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleSiteChange = this.handleSiteChange.bind(this);
        this.handleAccountTypeChange = this.handleAccountTypeChange.bind(this);
        this.handleGroupByChange = this.handleGroupByChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.getReport = this.getReport.bind(this);
        this.sortReport = this.sortReport.bind(this);
        this.getUserDefinedFields = this.getUserDefinedFields.bind(this);
    }

    componentDidMount() {
        this.getUserDefinedFields();
        // this.getReport();
    }

    handleBeginDateChange(e) {
        this.setState({ beginDate: e });
    }

    handleEndDateChange(e) {
        this.setState({ endDate: e });
    }

    handleSiteChange(siteId) {
        this.setState({ siteId: siteId });
    }

    handleAccountTypeChange(accountTypeId) {
        this.setState({ accountTypeId: accountTypeId });
    }

    handleGroupByChange(e) {
        this.setState({ groupBy: e.target.value });
    }

    handleStateChange(state) {
        this.setState({ stateId: state.stateID });
    }

    handleCompanyChange(companyId) {
        this.setState({ companyId: companyId });
    }

    getPrintPDFUrl(type, order) {
        return this.http.HOST + "/Print/Order?type=" + type + "&OrderID=" + order.orderID + "&OrderNumber=" + order.orderNumber;
    }

    getUserDefinedFields() {
        var params = {
            entity: "Account"
        }
        this.http.get("/api/Crm/GetUserDefinedFieldsForEntity", params).then(data => {
            var udfValues = [];
            for (var i = 0; i < data.length; i++) {
                if (data[i].selection === "Multiple") {
                    udfValues.push([]);
                }
                else {
                    udfValues.push(0);
                }
            }

            this.setState({
                userDefinedFields: data,
                udfValues: udfValues
            });
        });
    }

    handleUserDefinedFieldValueChange(e, index) {
        var udfValues = this.state.udfValues;
        udfValues[index] = e;
        this.setState({
            udfValues: udfValues
        });
    }

    getReport(e) {
        if (!!e) {
            e.preventDefault();
        }

        var udfCriterias = [];
        for (var i = 0; i < this.state.udfValues.length; i++) {
            var udf = this.state.userDefinedFields[i];
            if (udf.selection === "Multiple") {
                udfCriterias.push({
                    fieldName: "udf_" + udf.userDefinedFieldID.toString(),
                    operator: "includes",
                    values: this.state.udfValues[i],
                })
            }
            else {
                udfCriterias.push({
                    fieldName: "udf_" + udf.userDefinedFieldID.toString(),
                    operator: "equal",
                    value: this.state.udfValues[i],
                })
            }
        }

        var params = {
            beginDate: this.state.beginDate != null ? moment(this.state.beginDate).format('L') : "",
            endDate: this.state.endDate != null ? moment(this.state.endDate).format('L') : "",
            siteId: this.state.siteId,
            accountTypeId: this.state.accountTypeId,
            categoryId: this.state.categoryId,
            stateId: this.state.stateId,
            companyId: this.state.companyId,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
            udfCriterias: JSON.stringify(udfCriterias),
            groupBy: this.state.groupBy
        }

        sessionStorage.setItem(this.paramsKey, JSON.stringify(params));

        this.http.get('/api/Report/GetCustomerCategoryReport', params).then(data => {
            this.setState({ report: data });
        });
    }

    exportToZip() {
        var orderIds = [];
        for(let group of this.state.report.items){
            orderIds.push(...group.items.map((i) => i.orderID));
        }

        window.open(this.http.HOST + "/Print/Orders?type=SalesOrder&orderIds=" + orderIds.join(","));
    }

    sortReport(field) {
        if (this.sortBy !== field) {
            this.sortDirection = "ASC";
        }
        else {
            if (this.sortDirection === "ASC") {
                this.sortDirection = "DESC";
            }
            else {
                this.sortDirection = "ASC";
            }
        }
        this.sortBy = field;
        this.getReport();
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Customer Category Report</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={e => this.getReport(e)}>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label htmlFor="beginDate">Invoices From</label>
                                            <DatePicker className="form-control" selected={this.state.beginDate} onChange={this.handleBeginDateChange} />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="endDate">Invoices To</label>
                                            <DatePicker className="form-control" selected={this.state.endDate} onChange={this.handleEndDateChange} />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label>Site</label>
                                            <SitesSelect value={this.state.siteId} onChange={this.handleSiteChange} hasAllOption={true}></SitesSelect>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label>Account Type</label>
                                            <AccountTypesSelect value={this.state.accountTypeId} onChange={this.handleAccountTypeChange} hasAllOption={true}></AccountTypesSelect>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        {this.state.userDefinedFields.map((udf, index) =>
                                            <div className="form-group col-md-3">
                                                <UserDefinedFieldEditor field={udf} value={this.state.udfValues[index]} onChange={(e) => this.handleUserDefinedFieldValueChange(e, index)}></UserDefinedFieldEditor>
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label>State</label>
                                            <StateSelect countryID={211} value={this.state.stateId} onChange={this.handleStateChange} hasAllOption={true}></StateSelect>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="orderStatus">Company</label>
                                            <CompanySelect value={this.state.companyId} onChange={this.handleCompanyChange} hasAllOption={true}></CompanySelect>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="groupBy">Group By</label>
                                            <select className="form-control" value={this.state.groupBy} onChange={this.handleGroupByChange}>
                                                <option value="7">Category</option>
                                                <option value="6">Product Interest</option>
                                                <option value="8">Specialty</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-row justify-content-end">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {this.state.report != null &&
                            <Report name="CustomerCategoryReport"
                                hasData={this.state.report.items && this.state.report.items.length > 0}
                                title="Customer Category Report" description={this.state.report.description}
                                customActions={
                                    <button
                                        onClick={() => this.exportToZip()}
                                        className="btn btn-info btn-border btn-round btn-sm mr-2">
                                        <span className="btn-label">
                                            <i className="fas fa-file-archive"></i>
                                        </span>
                                        EXPORT TO ZIP
                                    </button>
                                }
                            >
                                <div className="form-row">
                                    <table className="table table-striped mt-1 report-table">
                                        {this.state.report.items.map((group) =>
                                            <React.Fragment key={group.name}>
                                                <thead>
                                                    <tr className="bg-primary reportHeader">
                                                        <th colSpan={8}>{group.name}</th>
                                                    </tr>
                                                    <tr>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("OrderNumber")}>INV #</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("FulfillDate")}>INV DATE</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("CustomerNumber")}>CUST #</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("CompanyName")}>CUSTOMER</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("StateAbbreviation")}>STATE</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("Site")}>SITE</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("FirstName")}>REP</th>
                                                        <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("SubTotal")}>SALES</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {group.items && group.items.map(item =>
                                                        <tr key={item.orderID}>
                                                            <td><a href={this.getPrintPDFUrl("Invoice", item)} target="_blank" rel="noopener noreferrer">{item.orderNumber}</a></td>
                                                            <td>{moment(item.fulfillDate).format('l')}</td>
                                                            <td><Link to={"/customer/" + item.customerID}>{item.customerNumber}</Link></td>
                                                            <td>{item.companyName}</td>
                                                            <td>{item.stateAbbreviation}</td>
                                                            <td>{item.site}</td>
                                                            <td>{item.firstName}</td>
                                                            <td className="currency">{this.utils.toCurrency(item.subTotal, item.currencyCode)}</td>
                                                        </tr>
                                                    )}
                                                    {group.sales.map((s, i) =>
                                                        <tr className="bg-light reportFooter">
                                                            <th colSpan={7}></th>
                                                            <th className="currency">{this.utils.toCurrency(s.amount, s.currency)}</th>
                                                        </tr>
                                                    )}

                                                </tbody>
                                            </React.Fragment>
                                        )}
                                        <tbody>
                                            {this.state.report.sales.map((s, i) =>
                                                <tr className="bg-dark reportFooter">
                                                    <th colSpan={7}></th>
                                                    <th className="currency">{this.utils.toCurrency(s.amount, s.currency)}</th>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Report>
                        }
                    </div>
                </div>
            </div >
        );
    }
}

export default CustomerCategoryReport;
